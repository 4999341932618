<template>
  <div class="wrap-lesson" :style="backgroundColor" v-if="lesson">
    <div class="container">
      <div class="row background-height">
        <div class="wrap-banner" ref="banner" :style="bannerBackgroundImage">
          <div class="row d-flex__nowrap ">
            <div class="teacher-icon" ref="teachericon" >
              <img
                @click="showTeacherPage"
                :src="lesson.number === 0 ? '' : infoIcon"
              />
            </div>
            <div
              class="lesson-image lesson-image-mr col-md-3 col-sm-3 col-xs-3"
              ref="lessonImage"
              v-if="image"
            >
              <img :src="image" />
            </div>
            <div class="lesson-title">
              <div
                v-tooltip="lesson.title"
                :style="{
                  color: lesson.color,
                  textShadow: ' 2px 2px rgba(0,0,0,0.4)',
                  top: lesson.titlePositionTop + 'px',
                  paddingLeft: lesson.titlePositionLeft + 'px',
                  maxWidth: lessonTitleWidth + 'px',
                }"
                style="position: relative"
              >
                {{ lesson.title }}
              </div>
              <essential-question
                v-show="false"
                v-if="essentialQuestionText"
                :key="lesson.essentialQuestion.id"
                :audio="essentialQuestionAudio"
                :message="essentialQuestionText"
              />
            </div>
          </div>
        </div>
        <div v-if="components.length == 0" class="display-message">
          {{ $t("noContentToDisplayMessage") }}
          <div class="col-12 prev-button">
            <div class="row">
              <button class="btn round primary back" @click="$router.go(-1)">
                {{ $t("back") }}
              </button>
            </div>
          </div>
        </div>
        <template v-else>
          <div class="lesson-container">
            <component
              v-for="component in components"
              :is="component.paragraph.type"
              :key="component.paragraph.id"
              :content="component.paragraph"
            />
          </div>
          <div class="controls-section flex-container lesson-button">
            <button class="btn round primary" @click="redirectToLessons">
              {{ btnText }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>

  <!-- <div class="lesson" v-if="lesson">
    <div class="lesson-banner row" :style="bannerBackgroundImage">
      <div class="banner-background" :style="backgroundColor"></div>
      <img class="info-icon" @click="showTeacherPage" :src="infoIcon" />
      <img :src="image" class="lesson-image" />
      <div class="column">
        <span
          class="lesson-title setPosition"
          v-tooltip="lesson.title"
          :style="{
            top: lesson.titlePositionTop + 'px',
            left: lesson.titlePositionLeft + 'px',
            color: lesson.color,
            textShadow: ' 2px 2px rgba(0,0,0,0.4)',
          }"
          >{{ lesson.title }}</span
        >
        <essential-question
          v-show="false"
          v-if="essentialQuestionText"
          :key="lesson.essentialQuestion.id"
          :audio="essentialQuestionAudio"
          :message="essentialQuestionText"
        />
      </div>
    </div>

    <div class="lesson-background" :style="backgroundColor"></div>
    <span v-if="components.length == 0" class="centered">{{
      $t("noContentToDisplayMessage")
    }}</span>
    
    <template v-else>
      <div class="lesson-container flex-container">
        <component
          v-for="component in components"
          :is="component.paragraph.type"
          :key="component.paragraph.id"
          :content="component.paragraph"
        />
      </div>

      <div
        :class="[
          !isReadButtonClicked ? 'controls-section flex-container' : 'd-none',
        ]"
      >
        <button class="btn round primary" @click="redirectToLessons">
          {{ btnText }}
        </button>
      </div>
      <div
        :class="[
          isReadButtonClicked ? 'controls-section flex-container' : 'd-none',
        ]"
      >
        <button class="btn round primary back" @click="$router.go(-1)">
          {{ $t("back") }}
        </button>
      </div>
    </template>
  </div> -->
</template> 

<script>
import AuthenticationService from "@/services/AuthenticationService.js";
import { Role } from "@/utils/Roles.js";
import LessonParagraph from "@/components/Lesson/LessonParagraph.vue";
import LessonTextContent from "@/components/Lesson/LessonTextContent.vue";
import VideoContent from "@/components/Lesson/VideoContent.vue";
import LessonCard from "@/components/Lesson/LessonCard.vue";
import SectionTitle from "@/components/Lesson/SectionTitle.vue";
import InteractiveMap from "@/components/Lesson/InteractiveMap.vue";
import InteractiveLesson from "@/components/Lesson/InteractiveLesson.vue";
import GlobalConnections from "@/components/Lesson/GlobalConnections.vue";
import EssentialQuestion from "@/common/EssentialQuestion.vue";
import OnlyImages from "@/components/Lesson/OnlyImages.vue";

export default {
  name: "LessonLayout",
  components: {
    paragraph: LessonParagraph,
    card: LessonCard,
    "section-title": SectionTitle,
    "text-content": LessonTextContent,
    InteractiveMap,
    InteractiveLesson,
    VideoContent,
    EssentialQuestion,
    "only-images": OnlyImages,
    GlobalConnections,
  },
  data() {
    return {
      lessonTitleWidth: null,
    };
  },
  computed: {
    unit() {
      return this.$store.state.currentUnit;
    },
    lesson() {
      return this.$store.state.currentLesson;
    },
    image() {
      return this.getImage(this.lesson.imageName);
    },
    bannerBackgroundImage() {
      return {
        backgroundImage: `url(${this.getImage(
          this.lesson.headerBackgroundName
        )})`,
      };
    },
    components() {
      return this.lesson.components;
    },
    infoIcon() {
      return require("@/assets/icons/ic_info.svg");
    },
    backgroundColor() {
      return {
        backgroundColor: this.lesson.background || "",
        opacity: this.lesson.opacity / 100 || 1,
      };
    },
    essentialQuestionText() {
      if (!this.lesson || !this.lesson.essentialQuestion) return null;
      let essentialQuestionText = this.lesson.essentialQuestion.question;
      return essentialQuestionText[this.locale].length > 0
        ? essentialQuestionText
        : null;
    },
    essentialQuestionAudio() {
      if (!this.lesson.essentialQuestion) return null;
      return this.lesson.essentialQuestion.audio;
    },
    btnText() {
      return this.$store.state.readClicked
        ? this.$t("back")
        : this.$t("lessons");
    },
    isReadButtonClicked() {
      return this.$store.state.isMovedToLessonStory;
    },
  },
  methods: {
    getImage(image) {
      if (image === 0 || !image) return;
      return `${process.env.VUE_APP_CDN}images/${image}`;
    },
    showTeacherPage() {
      if (this.$store.state.isPreviewModeActive) return;
      this.$router.push({
        name: "TeacherPageLesson",
        params: {
          teacherPageId: this.lesson.skillsId,
          lessonId: this.lesson.id,
          unitId: this.$route.params.unitId,
        },
        query: {
          title: this.lesson.title,
        },
      });
    },
    // goToAssessment() {
    //   this.$router.push({
    //     name: "Assessment",
    //     params: {
    //       ...this.$route.params,
    //     },
    //   });
    // },
    goToOpener() {
      // this.$router.push({
      //   name: "Opener",

      // })
      history.back();
      this.$store.commit("readbtnClicked", { redirectToOpener: false });
    },
    goToLessons() {
      this.$router.push({ name: "Unit", params: { unitId: this.unit.id } });
      this.setLessonCompleted();
      window.scrollTo(0, 0);
    },
    goToNextLesson() {
      let lessons = this.$store.state.currentUnit.lessons;
      let nextLesson = lessons.find((e) => e.number === this.lesson.number + 1);
      if (!nextLesson) {
        this.$router.push({
          name: "Unit",
          params: { unitId: this.$route.params.unitId },
        });
        return;
      }
      this.setLessonCompleted();
      this.$router.push({
        name: "Lesson",
        params: {
          unitId: this.$route.params.unitId,
          lessonId: nextLesson.id,
        },
      });
    },
    redirectToLessons() {
      if (this.$store.state.isPreviewModeActive) {
        this.goToLessons();
        return;
      }
      this.$store.state.readClicked ? this.goToOpener() : this.goToLessons();
    },
    setLessonCompleted() {
      let userRole = AuthenticationService.getProfile().roleName;
      if (Role.Student.name === userRole) {
        this.$store.dispatch("setLessonCompleted", this.lesson);
      }
    },
    getLessonTitleWidth() {
      var tIconWidth = this.$refs.teachericon.clientWidth;
      var lessonImageWidth = this.$refs.lessonImage.clientWidth;
      var calWidth = tIconWidth + lessonImageWidth;
      var bannerWidth = this.$refs.banner.clientWidth;
      this.lessonTitleWidth = bannerWidth - calWidth;
    },
  },
  created() {
    if (!this.lesson || this.lesson.id != this.$route.params.lessonId) {
      this.isLoadingInformation = true;
      this.$store.commit("loadLesson", null);
      this.$store
        .dispatch("loadLesson", this.$route.params.lessonId)
        .finally(() => {
          this.isLoadingInformation = false;
        });
    }
  },
  updated() {
    setTimeout(() => {
      this.getLessonTitleWidth();
    }, 1000);
  },
  mounted() {
    this.startTrackingPage({ sectionName: "Lessons" });
    this.$store.commit("setAnalyzedManagementSection", "Lessons");
  },
  beforeDestroy() {
    if (this.lesson) this.setLessonCompleted();
    this.$store.commit("setAnalyzedManagementSection", "");
    this.stopTrackingPage({ sectionName: "Lessons" });
  },
};
</script>

<style lang="less" scoped>
.setPosition {
  position: relative;
}
.none {
  display: none;
}
.wrap-lesson {
  font-family: "Roboto", sans-serif !important;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  z-index: 0;
  margin-top: 104px;
  height: calc(100% - 104px);
  background-color: white;
  padding-bottom: 30px;
  .wrap-banner {
    min-height: 242px;
    width: 100%;
    max-height: 250px;
    padding: 15px;
    position: relative;
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
    .teacher-icon {
      text-align: center;
      padding-top: 15px;
      padding-left: 15px;
    }
    .lesson-image {
      img {
        max-width: 100%;
        max-height: 244px;
      }
    }
    .lesson-title {
      color: #3a4dfd;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 0.25px;
      white-space: normal;
      margin-top: 0px;
    }
  }
  .display-message {
    width: 100%;
    text-align: center;
  }
  .lesson-container {
    width: 100%;
  }
}

.lesson {
  position: relative;
  justify-content: center;
  margin: 0 auto;
  z-index: 0;
  margin-top: 104px;
  height: 100vh;
  background-color: white;

  .lesson-banner {
    width: 1280px;
    height: 250px;
    max-width: 1280px;
    max-height: 250px;
    margin: 0 auto;
    padding: 16px;
    box-sizing: border-box;
    position: relative;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .info-icon {
      margin-left: 24px;
      margin-top: 16px;
      margin-bottom: auto;
    }

    .lesson-image {
      height: -webkit-fill-available !important;
      width: auto;
      margin-left: 30px;
      max-height: 244px;
      max-width: 468px;
    }
    .column {
      font-family: "Roboto", sans-serif !important;
      // margin-inline: auto;
      margin-left: 32px;
      margin-top: 37px;
    }
    .lesson-title {
      max-width: 820px;
      min-height: 41px;
      color: #3a4dfd;
      font-size: 40px;
      font-weight: 600;
      letter-spacing: 0.25px;
      // line-height: 41px;
      white-space: normal;
      max-height: 120px;
      text-align: center;
      // margin-left: 88px;
    }
    .essential-question {
      margin-top: auto;
      max-height: 136px;
      padding: 8px;
      box-sizing: border-box;
      margin-right: auto;
      margin-top: 84px;
      :global(span) {
        height: auto;
        min-height: 16px;
        white-space: normal;
        max-height: 100%;
        width: auto;
        max-width: 505px;
        overflow-y: auto;
        font-size: 14px;
        line-height: 16px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
          background: rgba(0, 0, 0, 0.2);
        }
      }

      :global(.brain-image) {
        height: 40px;
        width: 40px;
      }
    }
  }

  .lesson-container {
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
  }
  .btn {
    margin-left: auto;
    margin-top: 96px;
  }

  .lesson-background,
  .banner-background {
    z-index: -2;
    position: fixed;
    top: 0;
    min-height: 100%;
    width: 100%;
  }

  .banner-background {
    position: absolute;
    z-index: -1;
    width: 100%;
    max-height: 250px;
    height: 250px;
    left: 50%;
    transform: translate(-50%);
  }

  .controls-section {
    margin: 0 auto;
    width: 1280px;
    padding-bottom: 60px;
  }
}
.d-none {
  display: none;
}
.lesson-button {
  width: 100%;
  justify-content: flex-end;
}
.background-height {
  min-height: 86.7vh;
}
.prev-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  top: 45%;
}
.d-flex__nowrap{
  display: flex;
  flex-wrap: nowrap;
}
</style>